.App {
  text-align: center;
}

.App-logo {
  height: 64px;
  pointer-events: none;
}

.PayPlus{
  height: 72px;
}

.btnFormSend{
  cursor: pointer;
}

.input-group>.form-control:focus, .input-group>.form-select:focus {
  border: #57575b 0.5px solid;
  box-shadow:none;
}

.btnFormSend:hover{
  background-color: #ef0362;
  color: white;
}
/* 
footer p{
  margin: auto;
  color: "#57575b";
  font-size: 32px;
} */

footer{
  /* margin: 30px 0px; */
  position: fixed;
    bottom: 0;
    width: 100vw;
    height: 60px;
    line-height: 60px;
    background-color: #e9ecef;
}

footer p a{
  padding: 0 15px;
  border-right: #57575b 2px solid;
  cursor: pointer;
  color: #ef0362;
}

footer p a:hover{
  color: #57575b;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  /* min-height: 75vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #57575b;
}



@media screen and (max-width: 600px) { 
  #imageHolder{
    padding: 0 !important;
  }

  #imageItself{
    height: 250px;;
  }
}



.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
